import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  text: string;
  className: any;
  handleClick?: () => void; // Optional onClick prop
  style?: "primary" | "white"; // Optional style prop
}

export const Button = ({ text = "BUTTON TEXT", className, handleClick, style = "primary" }: Props): JSX.Element => {
  return (
    <button className={`button ${style} ${className}`} data-collection-1-mode="teal" onClick={handleClick}>
      {text}
    </button>
  );
};

Button.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  className: PropTypes.any,
  style: PropTypes.oneOf(["primary", "white"])
};
