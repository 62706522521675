import React, { useState } from "react";
import { ContactPopup } from "../ContactPopup";
import { Button } from "../../components/Button";
import { CaseBox } from "../../components/CaseBox";
import { Title } from "../../components/Title";
import { Arrow4 } from "../../icons/Arrow4";
import "./style.css";

export const Desktop = (): JSX.Element => {
  const [showContactPopup, setShowContactPopup] = useState(false);

  const handleContactPopup = () => {
    setShowContactPopup(!showContactPopup);
  }

  // window.onscroll = () => {
  //     const aboutMeImg = document.getElementById('about-me-img');
  //     const about = document.getElementById('about');

  //     if (aboutMeImg && about) {
  //       aboutMeImg.style.height = about.style.height;
  //       aboutMeImg.style.maxHeight = about.style.height;
  //     }
  // }

  return (
    <div className="desktop" data-collection-1-mode="teal">
      {showContactPopup && (
        <div className="popup-wrapper">
          <ContactPopup handleClick={handleContactPopup}/>
        </div>
      )}
      <div className="main">
        <div className="hero">
          <div className="hero-content">
            <Title
              alignment="left"
              className="design-component-instance-node"
              style="dark"
              textBottom="Coaching"
              textTop="Business communication"
            />
            <div className="hero-text-box">
              <div className="hero-text-main">
                <p className="text-wrapper">
                  Heb je je ooit afgevraagd hoe waardevol het zou zijn om vloeiend te communiceren in de wereld van
                  vandaag? Nou, dat is precies waar mijn passie ligt! Mijn bedrijf draait helemaal om Business
                  Communication Coaching. In deze wereld, waarin we allemaal met elkaar verbonden zijn, is het
                  essentieel om effectief te kunnen communiceren - vooral met mensen van diverse culturele achtergronden
                  binnen zakelijke contexten.
                </p>
                <Button className="design-component-instance-node" style="primary" text="PLAN EEN GESPREK" handleClick={handleContactPopup}/>
              </div>
              <div className="hero-text-supporting">
                <div className="flexcontainer">
                  <p className="text">
                    <span className="span">
                      Ik sta klaar om je mee te nemen op een boeiende reis, waarbij we samen stap voor stap werken aan
                      het ontwikkelen van jouw communicatieve vaardigheden. Niet alleen zal je meer zelfvertrouwen
                      krijgen in zakelijke gesprekken, maar je zult ook ontdekken hoe het verbinden met anderen op een
                      dieper niveau jouw professionele groei kan stimuleren.
                      <br />
                    </span>
                  </p>
                  <p className="text">
                    <span className="span">
                      Laten we samen de kunst van doeltreffende zakelijke communicatie verkennen en ervoor zorgen dat je
                      straalt in elke interactie. Of het nu gaat om het verfijnen van vergadergesprekken, het schrijven
                      van doeltreffende e-mails, of het verbeteren van presentatievaardigheden, mijn focus ligt op het
                      faciliteren van groei op een persoonlijk en professioneel niveau.  Jouw succes begint hier, en ik
                      ben er om je te begeleiden op jouw nieuwe pad!
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div" id="about">
          <div className="div-2">
            <img id="about-me-img-desktop" className="about-me-img" src="/img/about-extra-tall.png"></img>
            <img id="about-me-img-mobile" className="about-me-img" src="/img/about-wide.png"></img>
          </div>
          <div className="about-content">
            <Title
              alignment="left"
              className="design-component-instance-node"
              style="light"
              textBottom="I&#39;m Nicolette"
              textTop="Nice to meet you,"
            />
            <div className="about-text-block">
              <div className="about-text-line" />
              <div className="about-text">
                <div className="text-wrapper-2">Wie ben ik?</div>
                <p className="p">
                  Hello, ik ben Nicolette, jouw taal- en communicatiepartner! Mijn avontuur begon in  Nieuw-Zeeland,
                  waar ik ben opgegroeid met Nederlandse ouders. Mijn moedertaal is dus Engels, maar ik beheers ook
                  vloeiend de Nederlandse taal.
                </p>
                <div className="flexcontainer">
                  <p className="text-2">
                    <span className="text-wrapper-3">
                      Na mijn tijd als taalinstructeur in Amsterdam, heb ik met passie ELCT Language opgericht en ben ik
                      al meer dan 25 jaar toegewijd aan het begeleiden van professionals zoals jij. Wie zijn mijn
                      helden? Dat zijn die gedreven zakelijke medewerkers, snelle secretaresses, doortastende managers
                      en zelfverzekerde CEO&#39;s die ik met trots tot mijn klanten mag rekenen. Of je nu deel uitmaakt
                      van een financiële instelling, een internationaal bedrijf runt, betrokken bent bij een
                      overheidsafdeling, of als solopreneur door het leven gaat - mijn missie is om jouw Engelse
                      zakelijke en communicatieve vaardigheden naar nieuwe hoogten te tillen. Vanwege mijn uitgebreide
                      ervaring in het geven van Business Language Skills trainingen, begrijp ik de zakelijke context.
                      <br />
                    </span>
                  </p>
                  <p className="text-2">
                    <span className="text-wrapper-3">
                      En nu? Nu ben ik hier om jou te helpen groeien in de zakelijke wereld, want ik geloof dat
                      effectieve communicatie de sleutel is tot succes.
                      <br />
                    </span>
                  </p>
                  <p className="text-2">
                    <span className="text-wrapper-3">
                      Ben je nieuwsgierig geworden? Laten we dan niet wachten! Neem contact met me op voor een informeel
                      gesprek of stuur me een e-mail op{" "}
                    </span>
                    <a href="mailto:nu@elct.nl" rel="noopener noreferrer" target="_blank">
                      <span className="text-wrapper-4">nu@elct.nl</span>
                    </a>
                    <span className="text-wrapper-3">
                      . Ik kijk er naar uit om samen de mogelijkheden te verkennen!
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="about-button-area">
              <Button className="design-component-instance-node" style="white" text="KENNISMAKEN" handleClick={handleContactPopup}/>
            </div>
          </div>
        </div>
        <div className="tips">
          <div className="tips-content">
            <div className="tips-intro">
              <p className="text-wrapper-5">Zelfverzekerd en bekwaam in uw zakelijke communicatie</p>
              <p className="in-een-wereld-die">
                <span className="span">In een wereld die steeds meer divers wordt, staat </span>
                <span className="text-wrapper-6">communicatie</span>
                <span className="span">
                  {" "}
                  centraal.  Mijn begrip van de zakelijke wereld is geworteld in jarenlange ervaring en passie voor het
                  trainen van Business Language Skills. Wat ik echter steeds meer ontdek, is dat mijn passie ligt in het
                  coachen van professionals binnen deze
                </span>
                <span className="text-wrapper-6"> vertrouwde omgeving</span>
                <span className="span">. Het is niet alleen mijn expertise, maar ook mijn </span>
                <span className="text-wrapper-6">betrokkenheid</span>
                <span className="span"> bij het begeleiden van individuen en groepen in het </span>
                <span className="text-wrapper-6">versterken</span>
                <span className="span"> van hun communicatieve vaardigheden. </span>
                <span className="text-wrapper-6">Mijn doel</span>
                <span className="span">
                  {" "}
                  is niet alleen om de noodzakelijke taalvaardigheden te verbeteren, maar ook om een omgeving te creëren
                  waarin individuen zich{" "}
                </span>
                <span className="text-wrapper-6">zelfverzekerd</span>
                <span className="span">
                  {" "}
                  en bekwaam voelen in hun zakelijke communicatie. Ik zie het als een boeiende reis om samen met u te
                  werken aan uw communicatieve vaardigheden. Hieronder vind je een paar van de vele taaltips die jou
                  zullen helpen stevig in je schoenen te staan.
                </span>
              </p>
            </div>
            <div className="tips-tip-area">
              <div className="tips-top">
                <div className="tip-title">
                  <div className="text-wrapper-7">“Put that on paper”</div>
                </div>
                <div className="tips-top-padding" />
              </div>
              <div className="tips-bottom">
                <div className="tip-block">
                  <div className="tip-intro">
                    <p className="text-wrapper-8">
                      “Could you put that on paper, please?” Help! When your manager asks you this, where do you start?
                      Use the following checklist for guaranteed succes:
                    </p>
                  </div>
                  <div className="tip">
                    <div className="tip-box">
                      <div className="text-wrapper-9">S</div>
                      <div className="tip-box-text">
                        <div className="text-wrapper-10">Subject</div>
                        <div className="text-wrapper-11">What is the subject?</div>
                      </div>
                    </div>
                    <div className="tip-box">
                      <div className="text-wrapper-9">T</div>
                      <div className="tip-box-text">
                        <div className="text-wrapper-10">Timing</div>
                        <p className="text-wrapper-11">When do you need to present it?</p>
                      </div>
                    </div>
                    <div className="tip-box">
                      <div className="text-wrapper-9">A</div>
                      <div className="tip-box-text">
                        <div className="text-wrapper-10">Audience</div>
                        <div className="text-wrapper-11">Who is your audience?</div>
                      </div>
                    </div>
                    <div className="tip-box">
                      <div className="text-wrapper-9">L</div>
                      <div className="tip-box-text">
                        <div className="text-wrapper-10">Length</div>
                        <p className="text-wrapper-11">How long should it be?</p>
                      </div>
                    </div>
                    <div className="tip-box">
                      <div className="text-wrapper-9">K</div>
                      <div className="tip-box-text">
                        <div className="text-wrapper-10">Knowledge</div>
                        <p className="text-wrapper-11">What does(n’t) the audience know about the subject?</p>
                      </div>
                    </div>
                    <div className="tip-box">
                      <div className="text-wrapper-9">A</div>
                      <div className="tip-box-text">
                        <div className="text-wrapper-10">Aim</div>
                        <p className="text-wrapper-11">What do you want from your audience/readership?</p>
                      </div>
                    </div>
                    <div className="tip-box">
                      <div className="text-wrapper-9">S</div>
                      <div className="tip-box-text">
                        <div className="text-wrapper-10">Structure</div>
                        <p className="text-wrapper-11">How are you going to structure the information?</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tip-2">
                  <div className="text-wrapper-5">Typical Dutch grammar tips</div>
                  <div className="tip-box-text-wrapper">
                    <div className="tip-box-text-2">
                      <div className="text-wrapper-12">’Have’ is never afgelopen</div>
                      <p className="text-wrapper-13">
                        In Dutch ‘heb gedaan’ is afgelopen: in English ‘have done’ is nooit afgelopen!
                      </p>
                    </div>
                  </div>
                  <div className="tip-box-text-wrapper">
                    <div className="tip-box-text-2">
                      <p className="text-wrapper-12">‘Just’ and ‘yet’ go with ‘have’</p>
                      <p className="text-wrapper-13">
                        In British English, these words go with ‘have’ e.g.: “They have ‘just’ gone” and many people say
                        “They ‘just’ went”. Same goes with ‘yet’:  “They haven’t gone yet” NOT “They didn’t go ‘yet”.
                      </p>
                    </div>
                  </div>
                  <div className="tip-box-text-wrapper">
                    <div className="tip-box-text-2">
                      <p className="text-wrapper-12">“How do you do” is not “How are you?”</p>
                      <p className="text-wrapper-13">“How do you do” means “Hello”, not “How are you?”</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cases">
          <div className="cases-title">
            <Title
              alignment="left"
              className="design-component-instance-node"
              style="light"
              textBottom="Verhalen van coachees"
              textTop="Speaking the way you want to speak"
            />
          </div>
          <div className="cases-content">
            <div className="case-box-2" data-collection-1-mode="teal">
              <p className="case-title-2">Conveying exactly what you mean</p>
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-14">
                    A CEO and lobbyist of a non-profit organisation needed to become more fluent in communicating with
                    members, staff and English fundraisers, in writing and speaking. Her ultimate goal was to speak to
                    an audience about her convictions.
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-14">
                    Her understanding of English was academic as she was well-read, but she struggled with communicating
                    exactly what she thought and meant. Structured around her difficult schedule, our flexible sessions
                    encompassed discussion of her controversial topics along with practical presentation skills. 
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-14">
                    The meetings at her office paid off. In order to function in her demanding job as lobbyist and
                    speaker, she needed the necessary confidence to convey her opinions and views on this very
                    disputable topic. Now, she is able to express her opinions and views in her own way, using her
                    specific vocabulary and jargon more confidently. And yes, also in a more professional setting.{" "}
                  </span>
                </p>
              </div>
            </div>
            <CaseBox
              className="design-component-instance-node"
              text="Zij was een Officier van Justitie en door omstandigheden moest ze dat achter zich gelaten. Snel werd ze ontdekt door een groot advocatenkantoor waar ze verantwoordelijk werd voor klanten in onder andere de Verenigde Staten. Haar Engels was goed, maar haar zelfvertrouwen was onder de maat. Gauw,  door ons persoonlijke contact  en het geven van tips over ‘hoe’ je moet praten en ‘hoe’ ze in de VS denken, duurde het slechts een paar maanden (1 keer in de week of 2 weken) totdat ze vertrouwd genoeg voelde om het ZELF te gaan doen.  Nu is het een fluitje van een cent! Zij blij, ik blij!"
              title="Spreken met zelfvertrouwen"
            />
            <div className="case-box-2" data-collection-1-mode="teal">
              <p className="case-title-2">Speaking in your own style</p>
              <div className="flexcontainer">
                <p className="text-i">
                  <span className="text-wrapper-14">
                    A bank CEO was tasked with presenting reorganization plans to managerial staff. Our collaboration
                    emphasized aligning the communication approach with the CEO&#39;s personal style and the overarching
                    company culture, effectively incorporating his storytelling techniques. Weekly 1.5-hour meetings
                    were conducted on-site at the bank, during which we refined the presentation&#39;s narrative,
                    bolstered vocabulary, addressed grammatical nuances, and conducted practical rehearsals with
                    PowerPoint slides.
                    <br />
                  </span>
                </p>
                <p className="text-i">
                  <span className="text-wrapper-14">
                    At the programme&#39;s conclusion, the CEO expressed increased comfort and confidence in delivering
                    the message in a manner reflecting his unique leadership style. The structured sessions within the
                    bank premises contributed to a smooth integration of new communication styles so as to combine the
                    CEO&#39;s personal style of communicating with his executive responsibilities.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="begeleiding">
          <div className="begeleiding-content">
            <div className="begeleiding-left">
              <div className="begeleiding-usp-box">
                <div className="usp" data-collection-1-mode="teal">
                  <img className="img" alt="Nicolette icon suit" src="/img/nicolette-icon-suit-1.svg" />
                  <div className="usp-text">
                    <div className="USP-title">Ervaring</div>
                    <p className="by-elaborating-on">
                      Door vele jaren ervaring in de zakenwereld kan ik met je meepraten en snap ik de zakelijke
                      omgeving.
                    </p>
                  </div>
                </div>
                <div className="usp-2" data-collection-1-mode="teal">
                  <img className="img" alt="Nicolette icon face" src="/img/nicolette-icon-face-1.svg" />
                  <div className="usp-text">
                    <div className="USP-title">Persoonlijk</div>
                    <p className="by-elaborating-on">
                      Bij mij leer je de vaardigheden die jij nodig hebt op een manier die bij jou past.
                    </p>
                  </div>
                </div>
                <div className="usp-3" data-collection-1-mode="teal">
                  <img className="img" alt="Nicolette icon" src="/img/nicolette-icon-calendar-1.svg" />
                  <div className="usp-text">
                    <div className="USP-title">Flexibel</div>
                    <p className="by-elaborating-on">
                      De coachingsessies vinden plaats op de tijd en locatie die voor&nbsp;&nbsp;jou het beste werken.
                    </p>
                  </div>
                </div>
                <div className="usp-4" data-collection-1-mode="teal">
                  <img className="img" alt="Nicolette icon check" src="/img/nicolette-icon-check-1.svg" />
                  <div className="usp-text">
                    <div className="USP-title">Resultaat</div>
                    <p className="by-elaborating-on">
                      Mijn coachees behalen bijna altijd de doelen waarvoor ze gekomen zijn.
                    </p>
                  </div>
                </div>
              </div>
              <Button className="button-instance" style="white" text="MELD JE AAN VOOR HET TRAJECT" handleClick={handleContactPopup}/>
            </div>
            <div className="begeleiding-right">
              <div className="text-wrapper-15">Coachingtraject</div>
              <div className="begeleiding-image-container"><img className="begeleiding-image" src="/img/begeleiding.jpeg"></img> </div>
              <div className="begeleiding-text">
                <div className="flexcontainer">
                  <p className="span-wrapper">
                    <span className="text-wrapper-16">
                      Binnen dit coachingstraject leer ik je, aan de hand van je eigen wensen en binnen jouw eigen
                      jargon de kneepjes van het communiceren.  Denk hierbij aan het zelfverzekerder worden in het
                      Engels zakelijk presenteren en schrijven. Zo ook in storytelling en speeches geven. Alle
                      trainingen worden in-company gegeven (zo mogelijk online), zijn tailor-made naar eigen wensen en
                      beginnen met een gratis intakegesprek.
                      <br />
                    </span>
                  </p>
                  <p className="span-wrapper">
                    <span className="text-wrapper-16">
                      Als we het coachingtraject beginnen wil ik je graag persoonlijk ontmoeten, om te kijken of er een
                      click is. Wie ben je, wat doe je voor werk? Wat wil je leren en waarom? Waarvoor heb je het nodig?
                      Welke dagen/tijden kan je, waar (kantoor, off-site) is het beste?
                      <br />
                    </span>
                  </p>
                  <p className="span-wrapper">
                    <span className="text-wrapper-16">
                      Met deze vragen, ga ik te werk en ontwerp ik jouw programma. Of je nou spreekvaardigheid nodig
                      hebt, zelfvertrouwen nodig heb, grammatica, of alles nodig hebt? Als je met het voorstel eens
                      bent, gaan we samen werken aan jouw nieuwe vaardigheden. Stel dat het moeilijk gaat met aanleren,
                      dan kijken we hoe het anders kan - totdat jij het wél kan – een manier die bij je past! Dat vind
                      ik de leukste uitdaging die er is!{" "}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="reviews">
          <div className="reviews-content">
            <div className="quote" data-collection-1-mode="teal">
              <div className="text-wrapper-17">“</div>
              <div className="quote-content">
                <div className="quote-text">
                  <p className="sorry-for-my-english">No longer at a loss for words</p>
                  <p className="lorem-ipsum-dolor-2">
                    For my work as director of an NGO, I really needed to seriously improve my English. Nikolet improved
                    my knowledge of grammar and increased my vocabulary. In addition, she immersed herself in my world
                    to discuss it with me. After a year I was no longer at a loss for words and felt more confident.
                  </p>
                </div>
                <div className="quote-author">
                  <div className="ricky-jans-wrapper">
                    <div className="ricky-jans">NGO</div>
                  </div>
                  <div className="corporation-inc-wrapper">
                    <div className="corporation-inc">Director</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quote" data-collection-1-mode="teal">
              <div className="text-wrapper-17">“</div>
              <div className="quote-content">
                <div className="quote-text">
                  <div className="sorry-for-my-english">Tailor-made lessons</div>
                  <p className="lorem-ipsum-dolor-2">
                    My teacher comes to my desk, gives me tailor-made lessons and works around my schedule, so I
                    don&#39;t waste any time.
                  </p>
                </div>
                <div className="quote-author">
                  <div className="ricky-jans-wrapper">
                    <div className="ricky-jans">Diversey</div>
                  </div>
                  <div className="corporation-inc-wrapper">
                    <div className="corporation-inc">Brand Manager</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quote" data-collection-1-mode="teal">
              <div className="text-wrapper-17">“</div>
              <div className="quote-content">
                <div className="quote-text">
                  <div className="sorry-for-my-english">Invaluable support</div>
                  <p className="lorem-ipsum-dolor-2">
                    The personal attention that I receive is invaluable support for me in order to carry out a demanding
                    job in English.
                  </p>
                </div>
                <div className="quote-author">
                  <div className="ricky-jans-wrapper">
                    <div className="ricky-jans">ABN Amro Bank</div>
                  </div>
                  <div className="corporation-inc-wrapper">
                    <div className="corporation-inc">Financial Director</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="quote" data-collection-1-mode="teal">
              <div className="text-wrapper-17">“</div>
              <div className="quote-content">
                <div className="quote-text">
                  <div className="sorry-for-my-english">I feel more content</div>
                  <p className="lorem-ipsum-dolor-2">
                    The reason I took the course is that I needed to be able to express myself better on paper. I really
                    feel I have made good progress, my vocabulary has really increased and I feel more confident
                  </p>
                </div>
                <div className="quote-author">
                  <div className="ricky-jans-wrapper">
                    <div className="ricky-jans">ABN Amro Bank</div>
                  </div>
                  <div className="corporation-inc-wrapper">
                    <div className="corporation-inc">Executive Board Secretary</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="div">
          <div className="diensten-image-container">
            <img id="diensten-image-desktop" className="diensten-image" src="/img/diensten-tall.png"></img>
            <img id="diensten-image-mobile" className="diensten-image" src="/img/diensten-wide.jpeg"></img>
          </div>
          <div className="diensten-content">
            <Title
              alignment="right"
              className="title-instance"
              style="dark"
              textBottom="bij gebruiken?"
              textTop="Waar kun jij mij"
            />
            <div className="diensten-items">
              <div className="dienst">
                <img className="dienst-icon" alt="Dienst icon" src="/img/dienst-icon.svg" />
                <div className="dienst-text">
                  <div className="text-wrapper-18">Intercultural Communication Coaching</div>
                  <p className="text-wrapper-19">
                    Dit traject behelst het leren van vaardigheden om je te bewegen binnen zakelijke situaties en
                    verschillende culturele contexten. Denk hierbij aan cultureel afhankelijke etiquette, manieren van
                    feedback geven en non-verbale communicatie.
                  </p>
                  <div className="arrow-button" onClick={handleContactPopup}>
                    <Arrow4 className="arrow" />
                    <div className="text-wrapper-20">Neem contact op</div>
                  </div>
                </div>
              </div>
              <div className="dienst">
                <img className="dienst-icon" alt="Dienst icon" src="/img/dienst-icon-1.svg" />
                <div className="dienst-text">
                  <div className="text-wrapper-18">Translations &amp; Editing</div>
                  <p className="text-wrapper-19">
                    Nederlands – Engels
                    <br />
                    Engels – Nederlands
                  </p>
                  <div className="arrow-button" onClick={handleContactPopup}>
                    <Arrow4 className="arrow" />
                    <div className="text-wrapper-20">Neem contact op</div>
                  </div>
                </div>
              </div>
              <div className="dienst">
                <img className="dienst-icon" alt="Dienst icon" src="/img/dienst-icon-2.svg" />
                <div className="dienst-text">
                  <div className="text-wrapper-18">Andere Taaltrainingen</div>
                  <p className="text-wrapper-19">
                    Nederlands
                    <br />
                    Op verzoek: Spaans, Frans, Duits
                  </p>
                  <div className="arrow-button" onClick={handleContactPopup}>
                    <Arrow4 className="arrow"/>
                    <div className="text-wrapper-20">Neem contact op</div>
                  </div>
                </div>
              </div>
              <div className="dienst">
                <img className="dienst-icon" alt="Dienst icon" src="/img/dienst-icon-3.svg" />
                <div className="dienst-text">
                  <div className="text-wrapper-18">Personal Development</div>
                  <p className="text-wrapper-19">
                    Hier leer je strategieën en kaders om jezelf te ontwikkelen, met name hoe je doelen kan stellen en
                    verbeteringen aanbrengt in bijvoorbeeld je communicatie, studie of werk(-omgeving).
                  </p>
                  <div className="arrow-button" onClick={handleContactPopup}>
                    <Arrow4 className="arrow" />
                    <div className="text-wrapper-20">Neem contact op</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
