/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  textBottom: string;
  textTop: string;
  style: "dark" | "light";
  alignment: "right" | "left";
  className: any;
}

export const Title = ({
  textBottom = "Bottom text",
  textTop = "First line of the title",
  style,
  alignment,
  className,
}: Props): JSX.Element => {
  return (
    <div className={`title ${style} ${alignment} ${className}`} data-collection-1-mode="teal">
      <div className="title-left-top">
        <p className="first-line-of-the">{textTop}</p>
      </div>
      <div className="title-left-bottom">
        <div className="bottom-text">{textBottom}</div>
      </div>
    </div>
  );
};

Title.propTypes = {
  textBottom: PropTypes.string,
  textTop: PropTypes.string,
  style: PropTypes.oneOf(["dark", "light"]),
  alignment: PropTypes.oneOf(["right", "left"]),
};
