/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const Arrow4 = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`arrow-4 ${className}`}
      fill="none"
      height="12"
      viewBox="0 0 19 12"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path className="path" d="M0 5.99219H17.3305" stroke="white" strokeMiterlimit="10" strokeWidth="1.5" />
      <path
        className="path"
        d="M12.207 11.4322L17.3304 5.99197L12.207 0.551758"
        stroke="white"
        strokeMiterlimit="10"
        strokeWidth="1.5"
      />
    </svg>
  );
};
