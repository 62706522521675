/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  input: string;
  fieldTitle: string;
  style: "long" | "short";
  className: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Optional onChange prop
  value?: string;
}

export const InputField = ({
  input = "Field content",
  fieldTitle = "Field title",
  style,
  className,
  onChange,
  value
}: Props): JSX.Element => {
  return (
    <div className={`input-field ${className}`}>
      <div className="field-title">{fieldTitle}</div>
      <input
        type="text"
        className={`text-field ${style} field-content`}
        value={value}
        onChange={e => {
          if (onChange != undefined) {
            onChange(e)
          }
        }}
        placeholder={input}
      />
    </div>
  );
};

InputField.propTypes = {
  input: PropTypes.string,
  fieldTitle: PropTypes.string,
  style: PropTypes.oneOf(["long", "short"]),
  onChange: PropTypes.func,
  value: PropTypes.string
};
