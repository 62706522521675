import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../components/Button";
import { InputField } from "../../components/InputField";
import "./style.css";

interface Props {
  handleClick: () => void; // Optional onClick prop
}

export const ContactPopup = ({ handleClick }: Props): JSX.Element => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleNameChange = (event) => { setName(event.target.value); };
  const handleEmailChange = (event) => { setEmail(event.target.value); };
  const handleMessageChange = (event) => { setMessage(event.target.value); };

  const sendEmail = () => {
    const emailBody = `
      Hallo,
      
      Via het contact-formulier op de website heb ik het volgende bericht voor je achtergelaten:

      |  Naam: ${name},
      |  Email: ${email},
      |  Bericht: ${message}
      
      Met vriendelijke groeten,
      ${name}
    `;

    const subject = encodeURIComponent("Contact via website");
    const body = encodeURIComponent(emailBody);
    window.open(`mailto:${'elctnu@gmail.com'}?subject=${subject}&body=${body}`, '_blank');

    handleClick();
  }

  return (
    <div className="contact-popup" data-collection-1-mode="desktop">
      <div className="contact-popup-header">
        <div className="text-wrapper">Maak kennis met Nicolette</div>
      </div>
      <div className="contact-popup-input">
        <InputField
          className="design-component-instance-node"
          fieldTitle="Naam"
          input="Je volledige naam"
          style="short"
          value={name}
          onChange={handleNameChange}
        />
        <InputField
          className="design-component-instance-node"
          fieldTitle="E-mailadres"
          input="voorbeeld@example.com"
          style="short"
          value={email}
          onChange={handleEmailChange}
        />
        <InputField
          className="design-component-instance-node"
          fieldTitle="Bericht"
          input="Typ hier je bericht voor Nicolette..."
          style="long"
          value={message}
          onChange={handleMessageChange}
        />
      </div>
      <div className="popup-buttons">
        <Button className="design-component-instance-node" style="white" text="TERUG" handleClick={handleClick} />
        <Button className="design-component-instance-node" style="primary" text="VERSTUREN" handleClick={sendEmail} />
      </div>
    </div>
  );
};



ContactPopup.propTypes = {
  handleClick: PropTypes.func
};
